import React, { useState, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SearchIcon from "./search.svg";

// Import CSS files
import "./Css/Movie.css";
import "./Css/Pagination.css";
import "./Css/Navbar.css";
import "./Css/MovieDetails.css";
import "./Css/Search.css";
import "./Css/Empty.css";
import "./Css/Download.css";
import "./Css/Footer.css";

// Regular imports for Navbar and Footer
import Navbar from "./Components/Navbar";
import Navbar2 from "./Components/Navbar2";
import Home from "./Components/Home";
import LanguageMovies from "./Components/Languagesection";

// Lazy-loaded components for optimization
const MovieDetails = React.lazy(() => import("./Components/MovieDetails"));
const Aboutus = React.lazy(() => import("./Components/About"));
const Contactus = React.lazy(() => import("./Components/Contactus"));
const Disclaimer = React.lazy(() => import("./Components/Disclaimer"));
const Privacy = React.lazy(() => import("./Components/Privacy"));
const Terms = React.lazy(() => import("./Components/Terms"));
const GenreMovies = React.lazy(() => import("./Components/GenreMovies"));
const Footer = React.lazy(() => import("./Components/Footer"));




const API_URL = `https://api.themoviedb.org/3/discover/movie`;
const API_KEY = "f2d9abbddac1684b671d7d149d24779b";

// Cache to store fetched data
const cache = {};

// Fetch movies based on language
const fetchMoviesByLanguage = async (language, page = 1) => {
    const cacheKey = `${language}-${page}`;
    if (cache[cacheKey]) {
        console.log(`Returning cached data for ${cacheKey}`);
        return cache[cacheKey];
    }

    const url = `${API_URL}?api_key=${API_KEY}&with_original_language=${language}&page=${page}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error(`Error fetching movies: ${response.status}`);
        }
        const data = await response.json();
        cache[cacheKey] = data; // Cache the response
        return data;
    } catch (error) {
        console.error("Failed to fetch movies:", error.message);
        return { results: [], total_pages: 0 }; // Fallback response
    }
};

function App() {
    const [language, setLanguage] = useState(""); // Default language is English

    const onLanguageChange = (newLanguage) => {
        setLanguage(newLanguage); // Update language state
    };

    return (
        <div className="app">
            {/* Navbar components without lazy loading */}
            <Navbar />
            <Navbar2 onLanguageChange={onLanguageChange} />

            <Routes>
                {/* Remove lazy-loading for Home */}
                <Route
                    path="/"
                    element={<Home searchIcon={SearchIcon} />}
                />
                <Route
                    path="/genre/:genreName"
                    element={
                        <Suspense fallback={<div>Loading Genre Movies...</div>}>
                            <GenreMovies />
                        </Suspense>
                    }
                />
                {/* Remove lazy-loading for Languagesection */}
                <Route
                    path="/:language"
                    element={
                        <LanguageMovies
                            language={language}
                            fetchMoviesByLanguage={fetchMoviesByLanguage} // Pass fetch function as prop
                        />
                    }
                />
                <Route 
                    path="/movie/:slug"
                    element={
                        <Suspense fallback={<div>Loading Movie Details...</div>}>
                            <MovieDetails  />
                        </Suspense>
                    }
                />
                <Route
                    path="/aboutus"
                    element={
                        <Suspense fallback={<div>Loading About Us...</div>}>
                            <Aboutus />
                        </Suspense>
                    }
                />
                <Route
                    path="/contactus"
                    element={
                        <Suspense fallback={<div>Loading Contact Us...</div>}>
                            <Contactus />
                        </Suspense>
                    }
                />
                <Route
                    path="/disclaimer"
                    element={
                        <Suspense fallback={<div>Loading Disclaimer...</div>}>
                            <Disclaimer />
                        </Suspense>
                    }
                />
                <Route
                    path="/privacy"
                    element={
                        <Suspense fallback={<div>Loading Privacy Policy...</div>}>
                            <Privacy />
                        </Suspense>
                    }
                />
                <Route
                    path="/terms"
                    element={
                        <Suspense fallback={<div>Loading Terms...</div>}>
                            <Terms />
                        </Suspense>
                    }
                />
            </Routes>

            {/* Lazy-load Footer */}
            <Suspense fallback={<div>Loading Footer...</div>}>
                <Footer />
            </Suspense>
        </div>
    );
}

// Wrapper component for the Router
export default function AppWrapper() {
    return (
        <Router>

            <App />
        </Router>
    );
}
