import React from "react";
import { useNavigate } from "react-router-dom";

function MovieCard({ movie, className = "", loading = false }) {
    const navigate = useNavigate();
    const string = "katmoviehd";

    const languageMap = {
        en: "English",
        hi: "Hindi",
        zh: "Mandarin Chinese",
        es: "Spanish",
        ar: "Arabic",
        fr: "French",
        bn: "Bengali",
        ru: "Russian",
        pt: "Portuguese",
        te: "Telugu",
        de: "German",
        ja: "Japanese",
        ko: "Korean",
        ta: "Tamil",
        ur: "Urdu",
        kn: "Kannada",
        gu: "Gujarati",
    };

    // Function to generate a slug
    const slugify = (title, identifier, id) => {
        const formattedTitle = title
            ?.toLowerCase()
            .replace(/[^a-z0-9\s-]/g, "")
            .trim()
            .replace(/\s+/g, "-");
        return `${formattedTitle}-${identifier}-${id}`;
    };

    // Navigate to movie details
    const showMovieDetails = () => {
        const movieSlug = slugify(movie?.title, string, movie?.id);
        navigate(`/movie/${movieSlug}`, {
            state: {
                id: movie?.id,
                genres: movie?.genres,
                original_language: movie?.original_language,
            },
        });
    };

    if (loading) {
        // Render a placeholder skeleton when loading
        return (
            <div className={`movie placeholder ${className}`}>
                <div className="movie-card-placeholder"></div>
                <div className="movie-info-placeholder">
                    <div className="movie-title-placeholder"></div>
                    <div className="movie-language-placeholder"></div>
                </div>
            </div>
        );
    }

    return (
        <div className="movie" onClick={showMovieDetails}>
            <div>
                <p>{movie?.release_date || "Unknown Release Date"}</p>
            </div>
            <div className={`movie-card ${className}`}>
                {movie?.poster_path ? (
                    <a
                        href={`/movie/${slugify(
                            movie?.title,
                            string,
                            movie?.id
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            src={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                            alt={movie?.original_title || "Movie Poster"}
                            className="movies"
                            loading="lazy"
                            fetchpriority="high"
                        />
                    </a>
                ) : (
                    <div className="placeholder-poster">No Image Available</div>
                )}
            </div>

            <div>
                <span>
                    {languageMap[movie?.original_language] || "Unknown Language"}
                </span>
                <h3>{movie?.title || "Untitled Movie"}</h3>
            </div>
        </div>
    );
}

export default MovieCard;
