import { StrictMode } from "react";
import {createRoot} from "react-dom/client";
import App from "./App.jsx";
import "./Css/App.css";
import "./Css/Global.css";
import "./Css/Media.css";



const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <StrictMode>
        <App />
    </StrictMode>
);