import React, { useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import MovieCard from "./MoviesCard"; 

const API_URL = `https://api.themoviedb.org/3/discover/movie?api_key=f2d9abbddac1684b671d7d149d24779b&primary_release_year=${new Date().getFullYear()}`;
const API_URL2 = "https://api.themoviedb.org/3/search/movie?api_key=f2d9abbddac1684b671d7d149d24779b";


function useHideSearchBarOnMovieDetails() {
    const location = useLocation();
    return location.pathname === "/";
}

export default function Home({ searchIcon }) {
    const [movies, setMovies] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [language, setLanguage] = useState("hi");
    const [genre, setGenre] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const showSearchBar = useHideSearchBarOnMovieDetails();

    // Search movies by title
    const searchMovies = async (title) => {
        try {
            const response = await fetch(`${API_URL2}&query=${title}`);
            const data = await response.json();
            setMovies(data.results || []);
            setTotalPages(data.total_pages || 0);
        } catch (error) {
            console.error("Search failed:", error.message);
            setMovies([]);
        }
    };

    // Fetch movies by language and genre
    const fetchMoviesByLanguage = useCallback(async (lang, page = 1) => {
        try {
            const response = await fetch(
                `${API_URL}&with_original_language=${lang}&page=${page}${genre ? `&with_genres=${genre}` : ""}`
            );
            const data = await response.json();
            setMovies(data.results || []);
            setTotalPages(data.total_pages || 0);
        } catch (error) {
            console.error("Fetch failed:", error.message);
            setMovies([]);
        }
    }, [genre]);

    useEffect(() => {
        fetchMoviesByLanguage(language, currentPage);
    }, [language, genre, currentPage, fetchMoviesByLanguage]);

    const enterKeyPressed = (event) => {
        if (event.key === "Enter") {
            searchMovies(searchTerm);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prevPage) => prevPage + 1);
            window.scrollTo(0, 0);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prevPage) => prevPage - 1);
            window.scrollTo(0, 0);
        }
    };

    return (
        <>
            
            {movies.slice(0, 5).map((movie) => (
                <link
                    key={movie.id}
                    rel="preload"
                    href={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                    as="image"
                />
            ))}

            {showSearchBar && (
                <div className="search">
                    <input
                        placeholder="Search for movies"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        onKeyDown={enterKeyPressed}
                    />
                    <img src={searchIcon} alt="Search" onClick={() => searchMovies(searchTerm)} />
                </div>
            )}

            <div className="container">
                {movies.length > 0 ? (
                    movies.map((movie) => (
                        <MovieCard key={movie.id} movie={movie} />
                    ))
                ) : (
                    <div className="empty">
                        <h2>No Movies Found</h2>
                    </div>
                )}
            </div>

            <div className="pagination-container">
                <button 
                    onClick={handlePrevPage} 
                    disabled={currentPage === 1 || totalPages === 0}
                >
                    Previous
                </button>

                <span>
                    Page {currentPage} of {totalPages}
                </span>

                <button 
                    onClick={handleNextPage} 
                    disabled={currentPage === totalPages || totalPages === 0}
                >
                    Next
                </button>
            </div>
        </>
    );
}
