import React from "react";
import { Link } from "react-router-dom";

function Navbar2() {
    return (
        <nav className="navbar2">
            <div className="navbar-container">
                <div className="button-container">
                    <Link to="/english" target="_blank">
                        <button className="btn">English Movies</button>
                    </Link>
                    <Link to="/hindi" target="_blank">
                        <button className="btn">Hindi Movies</button>
                    </Link>
                    <Link to="/kannada" target="_blank">
                        <button className="btn">Kannada Movies</button>
                    </Link>
                    <Link to="/gujarati" target="_blank">
                        <button className="btn">Gujarati Movies</button>
                    </Link>
                    <Link to="/tamil" target="_blank">
                        <button className="btn">Tamil Movies</button>
                    </Link>
                    <Link to="/telugu" target="_blank">
                        <button className="btn">Telugu Movies</button>
                    </Link>
                </div>
            </div>
        </nav>
    );
}

export default Navbar2;
