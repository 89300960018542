import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import debounce from 'lodash.debounce';
import logo from "../Assets/katmoviehd.svg";

function Navbar({ onGenreChange }) {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    // Toggle mobile menu state
    const toggleMobileMenu = () => {
        setMobileMenuOpen((prev) => !prev);
    };

    // Debounced genre change handler
    const handleGenreChange = useCallback(
        debounce((genre) => {
            if (onGenreChange) onGenreChange(genre);
        }, 300),
        [onGenreChange]
    );

    const handleGenreClick = (genre) => {
        handleGenreChange(genre);
        toggleMobileMenu();
    };

    return (
        <nav className="navbar">
            <div className="navbar-container">
                {/* Logo Section */}
                <div className="navbar-logo">
                    <a href="../">
                        <img src={logo} alt="Logo" style={{ height: '30px' }} rel="preload"/>
                    </a>
                </div>

                {/* Hamburger Button for Mobile */}
                <div className="hamburger" onClick={toggleMobileMenu}>
                    ☰
                </div>

                {/* Navigation Buttons */}
                <div className={`navbar-buttons ${isMobileMenuOpen ? 'open' : ''}`}>
                    {[
                        "action",
                        "comedy",
                        "drama",
                        "horror",
                        "romance",
                        "sci-fi",
                        "animation",
                        "documentary",
                        "thriller",
                    ].map((genre) => (
                        <Link
                            key={genre}
                            to={`/genre/${genre}`}
                            className="navbar-button"
                            onClick={() => handleGenreClick(genre)}
                            target="_blank"
                        >
                            {genre.charAt(0).toUpperCase() + genre.slice(1)}
                        </Link>
                    ))}
                </div>
            </div>
        </nav>
    );
}

export default Navbar;
