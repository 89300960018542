import React, { useEffect, useState } from "react";
import MovieCard from "./MoviesCard";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

const API_URL = `https://api.themoviedb.org/3/discover/movie`;
const API_KEY = "f2d9abbddac1684b671d7d149d24779b";

const LanguageMovies = () => {
    const { language } = useParams(); // Extract language from URL
    const [movies, setMovies] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);

    const languageMap = {
        english: "en",
        hindi: "hi",
        kannada: "kn",
        gujarati: "gu",
        tamil: "ta",
        telugu: "te",
    };

    const languageCode = languageMap[language?.toLowerCase()] || "en";
    const genreTitle = language.charAt(0).toUpperCase() + language.slice(1);

    useEffect(() => {
        const fetchMovies = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `${API_URL}?api_key=${API_KEY}&with_original_language=${languageCode}&page=${page}`
                );
                const data = await response.json();
                setMovies(data.results || []);
                setTotalPages(data.total_pages || 0);
            } catch (error) {
                console.error("Error fetching movies:", error);
                setMovies([]);
            } finally {
                setLoading(false);
            }
        };

        fetchMovies();
    }, [languageCode, page]);

    useEffect(() => {
        setPage(1); // Reset page on language change
    }, [languageCode]);

    const nextPage = () => {
        if (page < totalPages) {
            setPage((prevPage) => prevPage + 1);
            window.scrollTo(0, 0);
        }
    };

    const prevPage = () => {
        if (page > 1) {
            setPage((prevPage) => prevPage - 1);
            window.scrollTo(0, 0);
        }
    };

    return (
        <>
            <Helmet>
                {movies.slice(0, 5).map((movie) => (
                    <link
                        key={movie.id}
                        rel="preload"
                        href={`https://image.tmdb.org/t/p/w500${movie.poster_path}`}
                        as="image"
                    />
                ))}
            </Helmet>

            <div className="container">
                <h2>{genreTitle} Movies</h2>
                {loading ? (
                    <>
                        <MovieCard loading />
                        <MovieCard loading />
                    </>
                ) : (
                    movies.map((movie) => (
                        <MovieCard key={movie.id} movie={movie} />
                    ))
                )}
                <br />
                <div className="pagination-container">
                    <button className="prev-btn" disabled={page === 1} onClick={prevPage}>
                        Previous
                    </button>
                    <span>
                        Page {page} of {totalPages}
                    </span>
                    <button
                        className="next-btn"
                        disabled={page === totalPages}
                        onClick={nextPage}
                    >
                        Next
                    </button>
                </div>
            </div>
        </>
    );
};

export default LanguageMovies;
